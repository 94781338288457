@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #000;
  color: #fff;
}

.App {
  font-family: 'Inter', sans-serif;
}
